import { Icon } from "@components/Icons"
import React from "react"

export const sidebarMenu = [
  {
    path: "/admin",
    icon: <Icon name="UserAdmin" />,
    name: "Usuários",
    children: [
      {
        path: "/admin/assinantes",
        name: "Assinantes",
      },
      {
        path: "/admin/criadores",
        name: "Criadores",
      },
    ],
  },
  {
    path: "/admin/ensaios",
    icon: <Icon name="EssayAdmin" />,
    name: "Ensaios",
  },
  {
    path: "/admin/postagens",
    icon: <Icon name="PostAdmin" />,
    name: "Postagens",
  },
  {
    path: "/admin/banners",
    icon: <Icon name="BannerAdmin" />,
    name: "Banners",
  },
]
