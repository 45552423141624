import { Avatar } from "antd"
import React from "react"

const formatName = (name) => {
  return name.split(" ")[0]
}

export const UserAvatar = ({ name }) => (
  <div className="pointer flex flex-row items-center justify-end mr12">
    <div className="mr12">
      <Avatar>{name.split("")[0]}</Avatar>
    </div>
    <div className="tr mine-shaft bold flex">{`Olá,  ${formatName(name)}`}</div>
  </div>
)
