import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"
import ProLayout from "@ant-design/pro-layout"
import { DialogModal, DialogTypes } from "@components/DialogModal"
import { Icon } from "@components/Icons"
import { sidebarMenu } from "@components/MenuAdmin"
import { UserAvatar } from "@components/UserAvatar"
import { UserMenu } from "@components/UserMenu"
import { useDimensions } from "@hooks/use-dimensions"
import { useStores } from "@hooks/use-stores"
import { EssayStatus } from "@modules/Essay/store"
import { Button, Dropdown, PageHeader } from "antd"
import cx from "classnames"
import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const ROUTE = {
  path: "/",
  name: "Home",
  routes: sidebarMenu,
}

const HeaderMenu = ({ collapsed }) => {
  return !collapsed ? (
    <div className="w-100 tc mt16">
      <Icon
        name="AnonimattaLogo"
        className={styles.customLogo}
        onClick={() => navigate("/admin")}
      />
    </div>
  ) : null
}

const RightHeader = ({ name, onClickLogout }) => (
  <Dropdown
    trigger={["click", "hover"]}
    overlay={<UserMenu onClickLogout={onClickLogout} />}
  >
    <div className="nowrap">
      <UserAvatar name={name} />
    </div>
  </Dropdown>
)

export const LayoutAdmin = ({
  children,
  subTitle,
  title,
  custombreadcrumbName,
  className,
  data,
  isEdit,
  handleDisableEssay,
}) => {
  const [routes, setRoutes] = useState()
  const [isClient, setIsClient] = useState(false)
  const [collapseMenu, setCollapseMenu] = useState(false)
  const [visible, setVisible] = useState(false)

  const isMobile = useMedia({ maxWidth: 767 })

  const { auth } = useStores()

  const [ref, { width }] = useDimensions()

  const { user, logout } = auth

  useEffect(() => {
    if (isMobile) setCollapseMenu(true)
    setIsClient(true)
  }, [])

  const handleClickLogout = () => logout()

  const handleCloseMenuMobile = () => {
    if (isMobile) {
      setCollapseMenu(true)
    }
  }

  if (!isClient) return null

  const controlCollapseMenu = () => setCollapseMenu((prev) => !prev)

  const breadcrumbRender = (router) => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : undefined

    router.unshift({
      path: "/admin",
      breadcrumbName: "Início",
    })

    if (!routes) {
      if (custombreadcrumbName && currentPath) {
        router.push({
          path: currentPath,
          breadcrumbName: custombreadcrumbName,
        })
      }
      setRoutes(router)
    }

    return router
  }

  const collapsedButtonRender = () =>
    collapseMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />

  const menuHeaderRender = () => <HeaderMenu collapsed={collapseMenu} />

  const rightContentRender = () => (
    <RightHeader name={user.name} onClickLogout={handleClickLogout} />
  )

  const menuItemRender = (itemProps, dom) => {
    return itemProps.isUrl ? (
      dom
    ) : (
      <Link to={itemProps.path} onClick={handleCloseMenuMobile}>
        {dom}
      </Link>
    )
  }

  const subMenuItemRender = (subMenuItemProps, defaultDom) =>
    subMenuItemProps.isUrl ? (
      defaultDom
    ) : (
      <Link to={subMenuItemProps.path || "/"}>{defaultDom}</Link>
    )

  const content = () => {
    const childrenWithProps = React.Children.map(children, (element) => {
      if (element) {
        return React.cloneElement(element, { contentWidth: width + 40 })
      }

      return null
    })

    const breadcumbItemRender = (route) => {
      // const handleClick = () => {
      //   const lastRouteIndex = _findLastIndex(routes)
      //   const secondRouteIndex = routes.indexOf(routes[1])
      //   const currentRouteIndex = routes.indexOf(route)
      //   const redirectRouteIndex = routes[2] || routes[currentRouteIndex]

      //   if (
      //     lastRouteIndex !== secondRouteIndex &&
      //     currentRouteIndex === secondRouteIndex
      //   ) {
      //     navigate(redirectRouteIndex.path)
      //     return
      //   }
      //   navigate(route.path)
      // }

      return (
        <Link to={route.path} tabIndex={0}>
          {route.breadcrumbName}
        </Link>
      )
    }

    const renderStatus = (status) => {
      switch (status) {
        case EssayStatus.ACTIVE:
          return (
            <span className="flex items-center">
              <div className={cx("bg-salem mr8", styles.circleStatus)} /> Ativo
            </span>
          )
        case EssayStatus.INACTIVE:
          return (
            <span className="flex items-center">
              <div
                className={cx("bg-scorpion-secondary mr8", styles.circleStatus)}
              />{" "}
              Inativo
            </span>
          )
        case EssayStatus.SCHEDULE:
          return (
            <span className="flex items-center">
              <div className={cx("bg-dodger-blue mr8", styles.circleStatus)} />{" "}
              Agendado
            </span>
          )
      }

      return null
    }

    return (
      <div ref={ref}>
        <PageHeader
          className="site-page-header"
          title={
            <span className="flex items-center f32 bold mine-shaft-dark">
              {title}

              <span className="f14 fw5 scorpion-main ml12">
                {isEdit && data ? renderStatus(data.status) : null}
              </span>
            </span>
          }
          breadcrumb={{
            routes,
            itemRender: breadcumbItemRender,
          }}
        >
          <div className="flex items-center justify-between">
            <span className="f16 mine-shaft-dark normal">{subTitle}</span>

            {isEdit && data.status === EssayStatus.ACTIVE && (
              <Button
                type="outline"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "4px",
                }}
                onClick={() => setVisible(true)}
              >
                Desativar
              </Button>
            )}
          </div>
        </PageHeader>

        {children && !isMobile ? childrenWithProps : children}

        {visible && (
          <DialogModal
            dialog
            dialogType={DialogTypes.CONFIRM}
            visible={visible}
            title="Deseja realmente desativar este ensaio?"
            onCancel={() => setVisible(false)}
            onOk={async () => {
              await handleDisableEssay()
              setVisible(false)
            }}
            okText="Desativar"
            cancelText="Cancelar"
          />
        )}
      </div>
    )
  }

  return (
    <div className={cx(styles.segoeUI, className)}>
      <ProLayout
        className="layoutAdminContext"
        title={title}
        navTheme="light"
        route={ROUTE}
        collapsed={collapseMenu}
        onCollapse={controlCollapseMenu}
        breadcrumbRender={breadcrumbRender}
        collapsedButtonRender={collapsedButtonRender}
        menuHeaderRender={menuHeaderRender}
        rightContentRender={rightContentRender}
        menuItemRender={menuItemRender}
        subMenuItemRender={subMenuItemRender}
      >
        {!data && isEdit ? "Carregando..." : content()}
      </ProLayout>
    </div>
  )
}
