import { LogoutOutlined } from "@ant-design/icons"
import { Menu } from "antd"
import React from "react"

export const UserMenu = ({ onClickLogout }) => (
  <Menu onClick={onClickLogout}>
    <Menu.Item key="1">
      <LogoutOutlined />
      {"Encerrar sessão"}
    </Menu.Item>
  </Menu>
)
