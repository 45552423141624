import "./style.module.less"

import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
  QuestionCircleFilled,
  WarningFilled,
} from "@ant-design/icons"
import { Layout, Modal } from "antd"
import cx from "classnames"
import React, { memo, useEffect } from "react"
import { useMedia } from "react-use-media"

export const DialogTypes = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  CONFIRM: "confirm",
}

const _DialogModal = (props) => {
  const {
    visible,
    title,
    onOk,
    onCancel,
    okText,
    cancelText = "Cancelar",
    children,
    dialog = false,
    dialogContent = "",
    dialogType,
    centered = true,
    footer = null,
    width = "60%",
    className = "",
    closable = true,
    wrapClassName = "",
    closeIcon,
  } = props

  const isMobile = useMedia({
    maxWidth: 1023,
  })

  useEffect(() => {
    if (visible && dialog) {
      const iconInfo = dialogType === DialogTypes.INFO && (
        <InfoCircleFilled style={{ color: "#128934" }} />
      )
      const iconSuccess = dialogType === DialogTypes.SUCCESS && (
        <CheckCircleFilled style={{ color: "#128934" }} />
      )
      const iconError = dialogType === DialogTypes.ERROR && (
        <CloseCircleFilled style={{ color: "#c143a8" }} />
      )
      const iconWarning = dialogType === DialogTypes.WARNING && (
        <WarningFilled style={{ color: "#faad14" }} />
      )
      const iconConfirm = dialogType === DialogTypes.CONFIRM && (
        <QuestionCircleFilled style={{ color: "#5c7a92" }} />
      )

      const confirmText =
        (dialogType === DialogTypes.INFO ||
          dialogType === DialogTypes.SUCCESS ||
          dialogType === DialogTypes.ERROR ||
          dialogType === DialogTypes.WARNING) &&
        !okText
          ? "OK"
          : okText || "Confirmar"

      Modal[dialogType]({
        centered,
        cancelText,
        okText: confirmText,
        title,
        icon:
          iconInfo || iconSuccess || iconError || iconWarning || iconConfirm,
        content: dialogContent,
        onOk,
        onCancel: dialogType === DialogTypes.CONFIRM && onCancel,
        cancelButtonProps: {
          type: "ghost",
          className: "CancelButtonModalConfirm",
        },
        okButtonProps: {
          type: "primary",
          style: {
            marginLeft: "0px",
          },
        },
      })
    }
  }, [visible && dialog])

  if (!visible) {
    return null
  }

  if (visible && dialog) {
    return null
  }

  const modalClasses = cx("CustomModal", className)

  return (
    <Layout.Content className="w-100 pa0 ma0 bg-white">
      <Modal
        width={isMobile ? "100%" : width}
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={footer}
        className={modalClasses}
        centered={centered}
        destroyOnClose
        cancelText={cancelText}
        closable={closable}
        wrapClassName={wrapClassName}
        closeIcon={closeIcon}
      >
        {children}
      </Modal>
    </Layout.Content>
  )
}

export const DialogModal = memo(_DialogModal)
